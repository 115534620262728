import { render, staticRenderFns } from "./block.vue?vue&type=template&id=657b2762"
import script from "./block.vue?vue&type=script&lang=js"
export * from "./block.vue?vue&type=script&lang=js"
import style0 from "./block.vue?vue&type=style&index=0&id=657b2762&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports