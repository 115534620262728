<template>
    <div class="record border border-box-stroke rounded-lg px-4 py-2 flex flex-col gap-2 text-xxs">
        <div class="record__title flex items-center gap-2 justify-between">
            <div class="record__title-label text-base-content-300">
                <b :title="userName">
                    {{ userName }}
                </b> made transition
            </div>
            <div
                class="record__title-date text-base-content-200 text-half"
                :title="changeDate"
            >
                {{ changeDate }}
            </div>
        </div>
        <div class="record__body flex items-center justify-between nowrap">
            <div class="record__change inline-flex flex-col gap-1 items-start">
                <div
                    class="value value--previous rounded-md bg-box-stroke px-2.5 py-0.5 text-half font-bold overflow-ellipsis"
                    :title="prevValue"
                >
                    {{ prevValue }}
                </div>
                <div class="px-1.5">
                    <font-awesome-icon
                        icon="arrow-down"
                        size="lg"
                    />
                </div>
                <div
                    class="value value--new rounded-md bg-box-stroke px-2.5 py-0.5 text-half font-bold overflow-ellipsis"
                    :title="newValue"
                >
                    {{ newValue }}
                </div>
            </div>
            <div
                v-if="changeTime"
                class="record__time inline-flex items-center gap-2 rounded-md bg-white-text-600 px-2.5 py-0.5 text-half"
                :title="`Time in source ${changeTime}`"
            >
                <span class="record__time-label">
                    Time in source
                </span>
                <span class="record__time-value text-primary">
                    {{ changeTime }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
    props: {
        record: {
            type: Object,
            required: true,
        },
        prevRecord: {
            type: Object,
        },
    },
    methods: {
        formatDate(date) {
            const format = "dd MMM yyyy 'at' HH:mm";
            return DateTime.fromJSDate(new Date(date)).toFormat(format);
        },
    },
    computed: {
        userName() {
            return this.record.user_name || 'User';
        },
        changeDate() {
            return this.formatDate(this.record.timestamp);
        },
        changeTime() {
            if (!this.prevRecord) return null;
            const units = {
                days: {
                    unit: 'days',
                    short: 'd',
                    order: 1,
                },
                hours: {
                    unit: 'hours',
                    short: 'h',
                    order: 2,
                },
                minutes: {
                    unit: 'minutes',
                    short: 'm',
                    order: 3,
                },
                seconds: {
                    unit: 'seconds',
                    short: 's',
                    order: 4,
                },
            }

            const start = DateTime.fromJSDate(new Date(this.prevRecord.timestamp));
            const end = DateTime.fromJSDate(new Date(this.record.timestamp));
            const diff = end.diff(
                    start,
                    [
                        units.days.unit,
                        units.hours.unit,
                        units.minutes.unit,
                        units.seconds.unit
                    ]
                ).toObject();
            return Object.entries(diff)
                .filter(([, value]) => !!value)
                .sort(([key1], [key2]) => units[key1].order - units[key2].order)
                .reduce((result, [key, value]) => {
                    result.push(`${Math.ceil(value)}${units[key].short}`);
                    return result;
                }, [])
                .join(' ');
        },
        prevValue() {
            if(this.record.field_base_type === 'Date') {
                const format = "dd MMM yyyy";
                return DateTime.fromJSDate(new Date(this.record.previous_value)).toFormat(format);
            } else {
                return this.record.previous_value || 'Null';
            }
        },
        newValue() {
            if(this.record.field_base_type === 'Date') {
                const format = "dd MMM yyyy";
                return DateTime.fromJSDate(new Date(this.record.new_value)).toFormat(format);
            } else {
                return this.record.new_value || 'Null';
            }
        },
       
    },
}
</script>

<style lang="scss" scoped>

</style>