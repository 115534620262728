<template>
    <div class="grid -mx-2 mb-4" :class="getColoumns()">
        <div class="m-2" v-for="(entity, idx) in entities" :key="generateKey(idx)" :class="getFieldColumns(entity)">
            <FormField
                v-if="entity.form_entity_type === 'FIELD'"
                :fieldData="entity"
                :parentData="layoutData"
                :path="[...path, {field: 'field_fe_id', value: entity.field_fe_id}]"
                :isMulti="checkIfMulti(entity, entities)"
                :validations="validations ? validations[idx] : null"
                :previewMode="previewMode"
                :previewForm="previewForm"
                :disabled="disabledFormBuilder"
                v-bind="$attrs"
                v-on="$listeners"
            />
        </div>
    </div>
</template>

<script>
import FormField from "./formField.vue";
import store from "../store";
import { checkIfMulti } from "../utils";

export default {
    props: {
        layoutData: {
            default: () => {},
            type: Object,
        },
        validations: {
            type: Object,
            default: () => null,
        },
        path: Array,
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false
        },
        // is used to prview filled form before its submitting:
        previewForm:{
            type: Boolean,
            default: false
        },
    },
    components: {
        FormField,
    },
    data() {
        return {
            checkIfMulti,
        }
    },
    computed: {
        entities() {
            return this.layoutData.entities;
        },
        getMaxConstraints() {
            return this.entities?.layout_max_constraint || 12;
        },
        disabledFormBuilder() {
            return store.readOnlyMode;
        },
    },
    methods: {
        getColoumns() {
            return ` grid-cols-${this.getMaxConstraints} `;
        },
        generateKey(index) {
            let key = ''
            key += this.entities[index].field_id + index 
            // update key for date picker component to re render correctly --> == 
            if (this.entities[index].type === 'date') key += this.entities[index].value
            return key
        },
        getFieldColumns(entity) {
            let classes = " ";
            if (entity?.width_constraint_max_lg) classes += ` lg:col-span-${entity?.width_constraint_max_lg || 12} `;
                else classes += ` lg:col-span-${entity?.width_constraint_max || 12} `;
            if (entity?.width_constraint_max_sm) classes += ` col-span-${entity?.width_constraint_max_sm || 12} `;
                else classes += ` col-span-${entity?.width_constraint_max || 12} `;
            if (entity?.width_constraint_max_md) classes += ` md:col-span-${entity?.width_constraint_max_md || 12} `;
                else classes += ` md:col-span-${entity?.width_constraint_max || 12} `;

            return classes;
        },
    },
};
</script>

<style>
</style>
