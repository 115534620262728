<template>
<div>
  <!--<GapErrors
    ref = 'scrollRef'
    class="block-gaps my-4"
    @select:gap="selectGap"
    :key="gap_errors.id"
    :message="gap_errors.message"
    :gapGroup="gapGroup"
    @reload="scrollToGaps"
    @showGapModal="showGapModal"
    /> -->
    <template>
      <div>
        <table class="w-full">
          <tr class="text-primary flex justify-between">
            <th class="gapHeading" :class="{'alignEmplyFiled' : getBlockTitle == '-'}">{{getBlockTitle}}</th>
            <th class="fromDate">From</th>
            <th class="toDate">To</th>
            <th class="actionItems">Action</th>
          </tr>
          <div v-if="!previewForm && !formAlreadySubmitted && !isFirstBlockView() && !firstBlockAdded" class="flex justify-center">
            <button type="button" class="mt-4 fillFirstBlock text-blue-900 bg-white border border-primary-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 ml-4 mb-2 " @click="fillFirstBlock()">Add New +</button>
          </div>
          <div v-else>
            <tr 
              v-for="(entity,idx) in this.getGapData" 
              :key="idx" 
              style="border-bottom: 2px dashed lightgray;" 
              :class="{ 'errorBlock' :  checkBlockError(entity)}"
              class="dropdownDots text-center" 
            >
              <td 
                :class="{'alignEmplyFiled' : getEntityLabel(entity,idx) == '-'}" 
                style="min-width:40px; word-break: break-word; width:132px; text-align: left"
              >
                {{getEntityLabel(entity,idx) === '-' ? '-' : getEntityLabel(entity, idx)}}
              </td>
              <td 
                :class="{'emptyblockTitleFrom' : getBlockTitle == '-'}" 
                style="text-align: left; width: 50px;"
              >
                {{entity.fromTime}}
              </td>
              <td 
                :class="{'emptyblockTitleTo' : getBlockTitle == '-'}" 
                style="text-align: left; width: 26px;"
              >
                {{entity.toTime}}
              </td>
              <td style="width: 69px;">
                <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" class="dropdownButton inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button" @click="handleChange(entity.block_fe_id)"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.0002 2.5C9.0835 2.5 8.3335 3.25 8.3335 4.16667C8.3335 5.08333 9.0835 5.83333 10.0002 5.83333C10.9168 5.83333 11.6668 5.08333 11.6668 4.16667C11.6668 3.25 10.9168 2.5 10.0002 2.5ZM10.0002 14.1667C9.0835 14.1667 8.3335 14.9167 8.3335 15.8333C8.3335 16.75 9.0835 17.5 10.0002 17.5C10.9168 17.5 11.6668 16.75 11.6668 15.8333C11.6668 14.9167 10.9168 14.1667 10.0002 14.1667ZM10.0002 8.33333C9.0835 8.33333 8.3335 9.08333 8.3335 10C8.3335 10.9167 9.0835 11.6667 10.0002 11.6667C10.9168 11.6667 11.6668 10.9167 11.6668 10C11.6668 9.08333 10.9168 8.33333 10.0002 8.33333Z" fill="#0D69D5"/>
                  </svg>
                </button>

                <!-- Dropdown menu -->
                <div :id="getDrop(entity.block_fe_id)" v-show="editVisible" class=" absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-30 dark:bg-gray-700 editBlockDropdown dark:divide-gray-600" :key="idx" style="position:absolute; right:1px; z-index:1">
                  <ul class="py-2 actionItemList text-sm text-gray-700 dark:text-gray-200;border-radius:5px" aria-labelledby="dropdownMenuIconButton">
                    <li @click="editBlock(entity.block_fe_id, idx)">
                      <a href="#" class="inline-flex pr-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        <span class="px-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M13.1066 7.98L11.9281 6.80148L4.16667 14.5629V15.7414H5.34517L13.1066 7.98ZM14.2851 6.80148L15.4636 5.62297L14.2851 4.44447L13.1066 5.62297L14.2851 6.80148ZM6.03553 17.4081H2.5V13.8726L13.6958 2.6767C14.0213 2.35126 14.5489 2.35126 14.8743 2.6767L17.2314 5.03372C17.5568 5.35916 17.5568 5.88679 17.2314 6.21223L6.03553 17.4081Z" fill="#0D69D5"/>
                          </svg>
                        </span>
                          Edit
                      </a>
                    </li>

                    <li @click="cloneBlock(entity.block_fe_id, idx)" type="button" :class="{'cursor-not-allowed opacity-25 pointer-events-none': isCloneBlockEnable.length == 0}" :disabled="true">
                      <a href="#" class="inline-flex pr-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        <span class="px-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M4.66653 3.99967V1.99967C4.66653 1.63149 4.96501 1.33301 5.3332 1.33301H13.3332C13.7014 1.33301 13.9999 1.63149 13.9999 1.99967V11.333C13.9999 11.7012 13.7014 11.9997 13.3332 11.9997H11.3332V13.9991C11.3332 14.3676 11.0333 14.6663 10.662 14.6663H2.67111C2.30039 14.6663 2 14.3699 2 13.9991L2.00173 4.66692C2.0018 4.29841 2.30176 3.99967 2.67295 3.99967H4.66653ZM3.33495 5.33301L3.33346 13.333H9.99987V5.33301H3.33495ZM5.99987 3.99967H11.3332V10.6663H12.6665V2.66634H5.99987V3.99967Z" fill="#0D69D5"/>
                          </svg>
                        </span>
                          Duplicate
                      </a>
                    </li>
         
                    <li @click="deleteBlock(entity.block_fe_id)" v-if="idx > 0" :class="{'cursor-not-allowed opacity-25 pointer-events-none': idx == 0}" type="button" >
                      <a href="#" class="inline-flex block pr-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        <span class="px-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M14.1665 4.99984H18.3332V6.6665H16.6665V17.4998C16.6665 17.9601 16.2934 18.3332 15.8332 18.3332H4.1665C3.70627 18.3332 3.33317 17.9601 3.33317 17.4998V6.6665H1.6665V4.99984H5.83317V2.49984C5.83317 2.0396 6.20627 1.6665 6.6665 1.6665H13.3332C13.7934 1.6665 14.1665 2.0396 14.1665 2.49984V4.99984ZM14.9998 6.6665H4.99984V16.6665H14.9998V6.6665ZM7.49984 3.33317V4.99984H12.4998V3.33317H7.49984Z" fill="#CE1B22"/>
                          </svg> 
                        </span>
                          Delete
                      </a>
                    </li>
                  </ul>
                </div>            
              </td>        
              <deleteModal 
                ref="delete-modal" 
                name="delete-modal"
                :index="idx"
                :deleteData="deleteData"
                @removeEntity="removeEntity"
              />
            </tr>
          </div>
        </table>
        <button v-if="!previewForm && !formAlreadySubmitted && isFirstBlockView()" type="button" class="mt-4 text-blue-900 bg-white border border-primary-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 ml-4 mb-2 " @click="addBlock()">Add New +</button>
      </div>
    </template>
  </div>        
</template>
<script>
const { DateTime } = require('luxon');
import deleteModal from "./deleteModal"
import store from "../store";
 import moment from 'moment';

export default {
  data(){
  return{
    dateLabel: "",
    fromDate:"",
    toDate:"",
    isAdd: false,
    editVisible: false,
    DateTime,
    store,
    gapBlock:[],
    deleteData:{},
    clickedInside: false,
    blockStorage:[],
    isFirstBlockFilled: false,
}
  },
  components:{
    deleteModal
  },
    props:{
        blockData: {
          type: Object,
          require: true,
        },
        // Used to determin if field can be copied. Is false when field is multi but can't be copied anymore:
        path: Array,
        validations: {
            type: Object,
            default: () => null,
        },
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false
        },
        // is used to prview filled form before its submitting:
        previewForm:{
            type: Boolean,
            default: false
        },
        linkedBlock: {
            type: Object,
            default: null
        },
        section: {
          type: Object,
          default: null
        },
        toggle: {
          type: Boolean,
          default: false
        },
        sIndex: {
          type: Number,
          default: 0
        },
        layoutData: {
          type: Object,
          default: () => {},
        },
        formAlreadySubmitted: {
          type: Boolean,
        },
        firstBlockAdded: {
          type: Boolean,
          default: false,
        },
        isGapClicked: {
          type: Boolean,
          default: false,
        },
        sectionData: {
          type: Array,
        },
        findErrors:{
         type:Object
        }
    
  },
  mounted(){
  
  },
  watch:{
    handleEntityChange: {
      deep: true,
      handler() {
      }
    }
  },
  created() {
    window.addEventListener('click', (event) => {
      const excludedSelectors = ['.editBlockDropdown', '.dropdownButton'];
      const isExcluded = excludedSelectors.some(selector => event.target.closest(selector));
      if (!isExcluded) {
        this.updateVisibility();
      }else {
        let clickedButton = document.querySelectorAll('.dropdownButton')
        clickedButton.forEach(el => {
          el.childNodes.forEach(cldEl => {
            if(cldEl != event.target) {
              return el
            }
          })
        })
      }
    });
  },
  computed: {
    isCloneBlockEnable () {
      return (
        this.blockData.entities.filter((el) => {
          if(el.field_type == 'Signature' && el.value != null) {
            return JSON.parse(el.value).value;
          }
          return el.value != null && el.value != "";
        })
      ) 
    },
    getCloneOption() {
      return this.isCloneBlockEnable.length > 0 ? true : false;
    },
    getBlockTitle() {
      let blockTitle = '-';
      if (this.blockData.block_title && this.blockData.block_title.length > 10) {
        blockTitle = this.blockData.block_title.substring(0, 15) + "..."
      } else if (this.blockData.block_title && this.blockData.block_title.length <= 10) {
        blockTitle = this.blockData.block_title;
      }
      return blockTitle;
    },
    handleEntityChange() {
      return store.list;
    },
    getDateLabel() {
      return this.currentData.label
    },
    getSectionData(){
       let data = [];
       for(let entity of this.section.entities){
        if(entity.visible && entity.form_entity_type === "BLOCK") {
          data.push(entity);
        }
       }
       return data;
    },
    getGapData(){
      let data = [];
      let store = [];
      let filterData = this.section.entities.filter((item) => item.block_id === this.blockData.block_id)
       for(let block of filterData){
          for(let formEntity of block.entities) {
            if(formEntity.field_type === "Date Range (Timeline)") {
              data.push(formEntity);
              store.push(block)
            }
          }
       }
      for(let i=0 ; i<data.length;i++){
      let fromTime = this.getFromDate(data[i])
      let toTime = this.getToDate(data[i])
      data[i].fromTime = fromTime;
      data[i].toTime = toTime;
      data[i].block_fe_id = store[i].block_fe_id;
      }
      return data;
    },
     isLinked() {
      if (!this.linkedBlockGroup) return null;
      // check block_id to include copies in calculations:
      return this.linkedBlockGroup.some((el) => el === this.blockData.block_id);
    },
    linkedBlockGroup() {
      // check block_id to include copies in calculations:
      return (
        this.linked_blocks.find((group) =>
          group.includes(this.blockData.block_id)
        ) || null
      );
    },
    linked_blocks() {
      return store.linked_blocks;
    },
  },
  methods:{
    getGap(){
      this.gapBlock = this.getGapData;
    },
    checkBlockError(entity) {
      let currentBlockError = false;
      let currentBlock = this.section?.entities?.find(el => el.block_fe_id == entity?.block_fe_id);
      let invalidBlock = currentBlock?.entities?.filter(elEnty => elEnty?.inValidBlock || this.getValidValue(elEnty));
      if (invalidBlock && invalidBlock?.length > 0 && this.findErrors?.isSaved && this.findErrors?.currentIndex == this.sIndex) {
        currentBlockError = true;
      }
      return currentBlockError;
    },
    getValidValue(elmt) {
      let currElmError = false;
      // if (elmt?.mandatory) {
      //   if (!elmt?.value || elmt?.value == null || elmt?.value == '') {
      //     currElmError = true;
      //   } else {
      //     currElmError = false
      //   }
      // } else {
      //   currElmError = false
      // }
      if(elmt.visible && !elmt?.render_conditionally && elmt?.mandatory) {
            if (!elmt?.value || elmt?.value == '' || elmt?.value == null) {
              currElmError = true;
            }
            else {
              currElmError = false;
            }
          } else if (elmt?.render_conditionally) {
            let parentField = this.blockData?.entities?.find(el => el?.field_id == elmt?.condition?.field_id)
            if (parentField?.value == elmt?.condition?.dependence_value && parentField?.field_id == elmt?.condition?.field_id) {
              if(elmt?.mandatory) {
                if (elmt?.value == '' || elmt?.value == null) {
                  currElmError = true;
                } else if(elmt.field_type == "Signature") {
                  let signValue = elmt?.value ? JSON.parse(elmt?.value) : elmt?.value
                  if(signValue && (signValue?.value == '' || !signValue?.value || signValue?.value == null)) {
                    currElmError = true;
                  }
                } else {
                  currElmError = false;
                }
              } else {
                currElmError = false;
              }
            } else {
              currElmError = false;
            }
          } else {
            currElmError = false;
          }
      return currElmError;
    },
    isFirstBlockView() {
      let showFirstBlock = false;
      let filledBlockData = []
      if (this.firstBlockAdded) {
        showFirstBlock = true;
        this.blockData.showFirstBlock = true;
      } else {
        this.blockData?.entities?.map(eldata => {
          if (eldata?.block_visibility || eldata?.field_type == 'Date Range (Timeline)') {
            if (eldata?.value || eldata?.value != null) {
              filledBlockData?.push(eldata);
            }
          }
        })
        if (filledBlockData && filledBlockData?.length) {
          showFirstBlock = true;
          this.blockData.showFirstBlock = true;
        } else {
          this.blockData.showFirstBlock = false;
        }
      }
      return showFirstBlock;
    },
    getEntityLabel(entity, idx) {
      let str = "";
      if(entity){
      if(this.isCloneBlockEnable != 0){
        let filterData = this.section?.entities.filter((item) => item?.block_id === this.blockData?.block_id);
        for(let i=0; i< filterData?.[idx]?.entities?.length; i++){
        if(['Input','Large text','Number','Date','Email'].includes(filterData?.[idx]?.entities[i]?.field_base_type) && filterData?.[idx]?.entities[i]?.field_type !== "Date Range (Timeline)"&& filterData?.[idx].entities?.[i]?.block_visibility)
        {
          if(filterData[idx].entities[i].value?.length < 20)
          {
              str+= filterData[idx].entities[i]?.value;
          }
          else {
            str += filterData[idx].entities[i]?.value?.substring(0,20)?.concat("...")
          }
        }
        // if(filterData[idx].entities[i].field_type == "Date Range (Timeline)")
        // {
        //   str+="-"
        // }
        if(i<filterData[idx].entities?.length-1 && filterData[idx].entities[i].block_visibility && filterData[idx].entities[i]?.field_type !== "Date Range (Timeline)")
        { 
          str+=",";
        }
      }
    }
      else{
      str="-"
      }
    }
    else{
      str="-"
    }
      str = str.split(',')
      str = str.filter((item)=> {
        return item!=='undefined'
      }).filter((item)=>item!=='')
      str = str.join(", ")
      return str;

    },
    // disableDeleteBtn(entity){
    //   let block = this.section.entities.filter((item) => item.block_fe_id === entity.block_fe_id)
    //   if(block.block_fe_id === block.block_id){
    //     return false;
    //   }
    //   else {
    //     return true;
    //   }
    // },
    
    getDrop(id){
      return "dropdownDots"+id;
    },
    addBlock() {
      let prevBlock_fe_id = this.getGapData[0].block_fe_id;
      let previousData = this.section.entities.find((item) => item.block_fe_id === prevBlock_fe_id)
      this.$emit("addBlock", previousData)
    },
    deleteBlock(block_fe_id) {
      this.clickedInside = true;
      this.closeEditDropdown(block_fe_id);
      this.deleteData = this.section.entities.find((item) => item.block_fe_id === block_fe_id);
      this.$refs["delete-modal"][0].showModal();
    },
    handleChange(idx) {
      this.toggleDropdown(idx);
    },
    updateVisibility() {
      let allDropdowns = document.querySelectorAll('.editBlockDropdown')
      allDropdowns.forEach(el => {
        el.style.display = 'none'
      });
    },
    closeEditDropdown(idx) {
      let editDropdown = document.getElementById("dropdownDots"+idx);
      editDropdown.style.display = "none";
    },
    toggleDropdown(idx)
    {
      var dropdown = document.getElementById("dropdownDots"+idx); // Get the dropdown menu element
      if(dropdown.style.display === "none"){
        dropdown.style.display = "block";
      }
      else {
      dropdown.style.display = "none";
      }
    },
    removeEntity() {
      this.$emit("removeEntityBlock",this.deleteData)
    },
    fillFirstBlock() {
      this.isFirstBlockView();
      let block_id = this.getGapData?.[0]?.block_fe_id;
      let filterData = this.section.entities.find((item) => item.block_fe_id === block_id);
      let firstAddBlock=true;
      this.$emit("editBlock", filterData, true, 0, firstAddBlock);
    },
    editBlock(block_id, blockIndex){
      // this.getGap();
      // this.gapBlock = this.getSectionData;
      // let filterBlockData = this.section.entities.filter((item)=>item.block_id === block_id)
      this.clickedInside = true;
      this.closeEditDropdown(block_id);
      let filterData = this.section.entities.find((item) => item.block_fe_id === block_id);
      this.$emit("editBlock", filterData, true, blockIndex)
    },
    cloneBlock(block_fe_id, blockIndex){
      // this.getGap();
      // this.gapBlock = this.getSectionData;
      this.clickedInside = true;
      this.closeEditDropdown(block_fe_id);
      let filterData = this.section.entities.find((item) => item.block_fe_id === block_fe_id)
      this.$emit("cloneBlock", filterData, blockIndex)
    },
    getFromDate(entity) {
      if(entity.value)
      {
      let fromDate = (JSON.parse(entity?.value)).from;
      let newFromdate = moment(fromDate, "YYYY-MM-DD HH:mm:ss").toString()
      return fromDate ? moment(newFromdate).format("DD MMM, YYYY") : '-'
      // return fromDate ? DateTime.fromJSDate(new Date(fromDate)).toFormat('dd LLL, yyyy') : '-';
      }
      else {
        let s = "-";
        return s;
      }
    },
    getToDate(entity) {
      if(entity.value)
      {
      let toDate = (JSON.parse(entity?.value)).to;
      let newTodate = moment(toDate, "YYYY-MM-DD HH:mm:ss").toString()
      return toDate ? moment(newTodate).format("DD MMM, YYYY") : '-'
      // return toDate ? DateTime.fromJSDate(new Date(toDate)).toFormat('dd LLL, yyyy') : '-';
      }
      else {
        let s = "-";
        return s;
      }
    },
    // getDel(){
    //   if(this.getGapData.length == 1){
    //     return true;
    //   }
    //   else{
    //     return false
    //   }
    // }
  }
  
   
}
</script>
 
<style>
td{
  padding:15px 0;
}
th {
  padding-top: 15px;
}
.gapHeading {
  min-width: 150px;
  word-break: break-word;
  width: 160px;
  text-align: left;
  font-weight: 300; 
}

.dropdownDots {
  display: flex;
  justify-content: space-between;
}

.fromDate, .toDate {
  width: 50px;
  font-weight: 300;
  text-align: left;
}

.toDate {
  margin-left: 36px;
}

.actionItems {
  text-align: end;
  font-weight: 300;
  width: 79px;
}

.actionItemList {
  text-align: left;
}

.actionItemList li {
  border-bottom: 1px solid #E9E9EA;
  margin: 0 10px;
  padding: 6px 0;
}

.alignEmplyFiled {
  text-align: center !important;
  width: 132px;
  min-width: 132px;
}

.emptyblockTitleFrom {
  width: 65px !important;
}

.emptyblockTitleTo {
  width: 41px !important;
}

.fillFirstBlock {
  width: 225px;
}

.errorBlock {
  border: 1px solid #ff000070 !important;
  background: #ff00000a;
  border-radius: 6px;
}
@media screen and (min-width: 320px) and (max-width: 382px){
    .actionItems {
      width: max-content !important;
    }
    .gapHeading {
      min-width: 95px;
      width: 98px;
    }
    .fromDate {
      margin-left: 40px;
    }
    .toDate {
      margin-left: 21px;
    }
    .fromDate, .toDate {
        width: 16%;
    }
}
</style>